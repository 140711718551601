import {Avatar, ListItem, Text} from "react-native-elements";
import React, {useMemo} from "react";
import {useTheme} from "@react-navigation/native";
import {StyleSheet} from "react-native";
import common from '../text/Common.json';

const OwnerCard = ({host})=>{
    const {colors} = useTheme();
    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                width: "100%",
                flex:1
            },
            backgroundColor: {
                backgroundColor: colors.background
            },
            textColor: {
                color: colors.text
            },
            textHeader: {
                fontWeight: "bold"
            }
        });
    })
    return (
        <ListItem containerStyle={styles.backgroundColor} style={[styles.container, styles.backgroundColor]}>
            <Avatar source={{uri: host.image}} />
            <ListItem.Content style={[styles.textColor, styles.backgroundColor]}>
                <ListItem.Title style={[styles.textColor, styles.backgroundColor]}>
                    <Text style={[styles.textColor, styles.textHeader]}>{`${common.event_sponsor}: `}</Text> {host.name}
                </ListItem.Title>
            </ListItem.Content>
        </ListItem>
    )
}

export default OwnerCard;