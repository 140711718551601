import {ADD_TO_CART, EMPTY_CART, UPDATE_CART } from '../constants';


const initialState = {
    cart: []
};

const eventReducer = (state = initialState, action) => {
    function isSameItem(newItems, i) {
        return action.payload.event.parentId === newItems[i].event.parentId && action.payload.event.itemId === newItems[i].event.itemId;
    }

    switch(action.type) {
        case ADD_TO_CART:
            const newItems = [...state.cart];
            let addFlag = true;
            for(let i = 0; i < newItems.length; i++){
                if(isSameItem(newItems, i)){
                    addFlag = false;
                    newItems[i].quantity += action.payload.quantity
                }
            }

            if(addFlag){
                newItems.push(action.payload)
            }

            return {
                ...state,
                cart: newItems
            };
        case EMPTY_CART:
            return {
                ...state,
                cart: []
            };
        case UPDATE_CART:
            return{
                state,
                cart: action.payload
            }
        default:
            return state;
    }
}
export default eventReducer;