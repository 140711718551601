import {SIGN_IN, SIGN_OUT, RESTORE_TOKEN} from '../constants';
import AsyncStorage from '@react-native-async-storage/async-storage';


export function saveLoginToState(authSession) {
        try {
            AsyncStorage.setItem('userToken', JSON.stringify(authSession));
        } catch (e) {
            console.log(e);
        }
    return {
        type: SIGN_IN,
        payload: authSession
    }
}

export function signOut() {
    AsyncStorage.removeItem('userToken');
    return {
        type: SIGN_OUT
    }
}
export function restoreToken() {
    return {
        type: RESTORE_TOKEN,
        payload: JSON.parse(AsyncStorage.getItem('userToken'))
    }
}