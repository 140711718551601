import React from "react";
import { FlatGrid } from 'react-native-super-grid';
import {ImageBackground, ScrollView, StyleSheet, Text, View} from "react-native";
import { Tile } from 'react-native-elements';
import {useTheme} from "@react-navigation/native";
import {Badge} from 'react-native-elements';

const Grid = ({data}) => {
    const [items, setItems] = React.useState(data);
    const itemDimension = 130;
    const {colors} =useTheme();
    const styles = StyleSheet.create({
        gridView: {
            marginTop: 10,
            flex: 1,
        },
        itemContainer: {
            justifyContent: 'flex-end',
            borderRadius: 5,
            height: 150,
        },
        itemName: {
            fontSize: 24,
            lineHeight: 26,
            color: colors.text,
            fontWeight: '600',

        },
        itemCode: {
            fontWeight: '600',
            fontSize: 12,
            color: '#fff',
        },
        image: {
            flex: 1,
            resizeMode: "cover",
            justifyContent: "center"
        },
        badge : {
            backgroundColor:colors.card,
            paddingRight: 10,
            paddingLeft:10,
            marginTop:5,
            marginBottom:5,
            height: 24,
            borderColor:colors.card
        }
    });
    return (
        <FlatGrid
            itemDimension={itemDimension}
            data={items}
            style={styles.gridView}
            spacing={10}
            renderItem={({ item }) => (
                <View style={[styles.itemContainer]}>
                    <ImageBackground resizeMode={"stretch"} source={{uri: item.img}} style={[styles.image]}>
                        <Badge badgeStyle={styles.badge} value={<Text style={styles.itemName}>{item.name}</Text>}/>
                    </ImageBackground>
                </View>
            )}
        />
    );
}



export default Grid;