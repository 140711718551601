import { GET_PERSON, LOGIN_PERSON, SET_OWNED_EVENTS, GET_OWNED_EVENTS, STAR_EVENT, UNSTAR_EVENT } from '../constants';

export function getPerson(userData) {
    return {
        type: GET_PERSON,
        payload: userData.data()
    }
}

export function loginPerson(currentLogin) {
    const login = () => !currentLogin;
    return {
        type: LOGIN_PERSON,
        payload: login()
    }
}

export function getOwnedEvents() {
    return {
        type: GET_OWNED_EVENTS
    }
}

export function setOwnedEvents(events) {
    return {
        type: SET_OWNED_EVENTS,
        payload: events
    }
}

export function starEvent(eventId) {
    return {
        type: STAR_EVENT,
        payload: eventId
    }
}

export function unStarEvent(eventId) {
    return {
        type: UNSTAR_EVENT,
        payload: eventId
    }
}
