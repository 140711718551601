import React from 'react';
import {connect} from 'react-redux';
import {createStackNavigator} from "@react-navigation/stack";
import EventsHome from "../screens/EventsHome";
import DetailsScreen from "../screens/DetailsScreen";
import {useTheme} from "@react-navigation/native";
import SearchHome from "../screens/SearchHome";

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

function EventStack(props) {
        const Stack = createStackNavigator();
        const theme = useTheme();

        return (
            <Stack.Navigator>
                <Stack.Screen
                    name="Home"
                    component={EventsHome}
                    options={{ headerShown: false }}
                />
                <Stack.Screen name="Details" component={DetailsScreen} />
                <Stack.Screen
                    name="Search"
                    component={SearchHome}
                    options={{ headerShown: false }}
                />
            </Stack.Navigator>
        );
}

export default connect(
    mapStateToProps,
)(EventStack);