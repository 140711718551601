import firebase from 'firebase';
import { getEvents } from '../redux/actions/EventActions'

const mocks = require('../mocks/event.json');

function loadEventsFromDB() {
    return function (dispatch) {
        return firebase.firestore().collection('events').limit(10).get().then(
            events => dispatch(getEvents(events)),
            err => console.log(err)
        );
    }
};

function eventSearch(searchString){
    return function (dispatch) {
        return firebase.firestore().collection('events')
        .where('searchKeywords', 'array-contains', searchString.toLowerCase()).get()
        .then(
            events =>  dispatch(getEvents(events)),
            err => console.log(err)
        );
    }
}
async function loadEventById(eventId) {
        return await firebase.firestore().collection('events').where('id', '==', eventId);
};

const saveMockEventsToDB = async () => {
    mocks.events.forEach(mock => {
        let eventsResult = firebase.firestore().collection('events').add({
            ...mock,
            searchKeywords: generateSearchKeywords(mock.eventName),
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }).catch(function (error) {
            console.log('Error writing new message to database', error);
        });
        console.log(eventsResult);
    });
};

const createEvent = async (eventObject) => {
    let eventResult = await firebase.firestore().collection('events').add({
        ...eventObject,
        searchKeywords: generateSearchKeywords(eventObject.eventName),
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
    }).catch(function (error) {
        console.log('Error writing new event to database', error);
    });
    return eventResult;
};

function generateSearchKeywords(eventName){
    const keywords = [];
    let currentWordFragment = '';
    eventName.split('').forEach(letter => {
        currentWordFragment += letter.toLowerCase();
        keywords.push(currentWordFragment);
    });
    return keywords;
}


export { loadEventsFromDB, saveMockEventsToDB, createEvent, loadEventById, eventSearch };
