import {Linking, Platform, StyleSheet} from "react-native";
import {refreshTextArray, searchTextArray} from "../text/MessageArrays";

export const makeDollar = (price) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
}

export const isEmailSignature = (checkVal) => {
    const validEmailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    if(checkVal){
        if(checkVal && checkVal.match(validEmailRegex)){
            return true;
        }
    }
    return false;
}

export const isDigit = (checkVal) => {
    const validDigit = /\d+/
    if(checkVal !== undefined || checkVal !== null){
        if(checkVal && checkVal.match(validDigit)){
            return true;
        }
    }

    return false;
}

export function getBrandPrimary() {
    if(Platform.OS === 'ios') {
        return 	"#007aff";
    } else if(Platform.OS === 'android') {
        return "#3F51B5";
    }
    return 	"#007aff";
}

export function openMap(address) {
    if(Platform.OS === 'ios'){
        Linking.openURL(`http://maps.apple.com/?address=${encodeURIComponent(address)}`)
    } else {
        Linking.openURL(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`)
    }
}

export function getRandomRefreshMessage() {
    return getRandomValuefromArray(refreshTextArray());
}

export function getRandomSearchMessage() {
    return getRandomValuefromArray(searchTextArray());
}

function getRandomValuefromArray(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}