/* eslint-disable */
import React, {useMemo} from 'react';
import { TouchableWithoutFeedback, Text, View } from 'react-native';
import { Button, Icon, Badge } from 'native-base';
import common from '../text/Common.json';
import EventHeader from './EventHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { focusEvent } from '../redux/actions/EventActions';
import { bindActionCreators } from 'redux';
import { useTheme } from "@react-navigation/native";
import { setOwnedEvents } from "../redux/actions/PersonActions";
import { Card } from "react-native-elements";
import { starEventForProfile, unStarEventForProfile } from '../api/PersonDataAPI'
import * as Analytics from 'expo-firebase-analytics';
import {buttonPress_FavoriteItem_EventsHome, buttonPress_ViewDetails_EventsHome} from "../utils/analytics";

const ActivityCard = (props) => {
    const isOwnedEvent = useMemo(() => {
        return props?.ownedEvents;
    }, [props])
    const actions = useMemo(() => {
        return props?.actions;
    }, [props])
    const { images, eventName, location } = useMemo(() => {
        return props?.event;
    }, [props?.event])
    const {price, capacity} = useMemo(() => {
        let price, capacity;
        if (props?.ticketTypes && props?.ticketTypes[0]) {
            price = props?.ticketTypes[0]?.price;
            capacity = props?.ticketTypes[0]?.capacity;
        }
        return {price , capacity}
    }, [props?.ticketTypes, props?.ticketTypes[0]]);

    const { colors } = useTheme();
    const isStarred =  useMemo(() => {
        return props?.starredEvents?.includes(props?.event?.id);
    }, [props?.event?.id, props?.starredEvents])
    const card = useMemo(() => {
        return  (
            <Card.Image source={{ uri: images[0] }}>
                {
                    isOwnedEvent ? <></> :
                        <Button transparent style={{ marginLeft: 10 }}>
                            <Badge style={{ backgroundColor: colors.card }}>
                                <Icon style={{ color: colors.text, fontSize: 20, lineHeight: 20 }}
                                      name={isStarred ? 'star' : 'star-outline'}
                                      onPress={async () => {
                                          isStarred ?
                                              props.actions.unStarEventForProfile(props.event.id) :
                                              props.actions.starEventForProfile(props.event.id);
                                          await buttonPress_FavoriteItem_EventsHome()
                                      }}
                                />
                            </Badge>
                        </Button>
                }
            </Card.Image>
        )
    }, [isOwnedEvent, images, colors, isStarred ])

    return (
        <TouchableWithoutFeedback onPress={async () => {
            actions.focusEvent(props.event);
            props.navigation.navigate('Details');
            await buttonPress_ViewDetails_EventsHome()
        }}>
            <Card containerStyle={{ backgroundColor: colors.card, borderColor: colors.card, padding: 0, minHeight: 250 }}>
                {images ? card : <></>}
                <View style={{ padding: 10 }}>
                    <EventHeader name={eventName} location={location} price={price} />
                    {capacity ? <Text style={{ color: colors.text }}>{common.availability}:{capacity}</Text> : <></>}
                </View>
            </Card>
        </TouchableWithoutFeedback >
    );
}

ActivityCard.propTypes = {
    price: PropTypes.number,
    capacity: PropTypes.number,
    img: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    address: PropTypes.object
}

const mapStateToProps = state => ({
    focusedEvent: state.EventData.focusedEvent,
    starredEvents: state.PersonData.userData.starredEvents
});

const ActionCreators = Object.assign(
    {},
    { focusEvent },
    { setOwnedEvents },
    { starEventForProfile },
    { unStarEventForProfile }
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCard);