//This should be moved to firebase actually

export const SearchCategories = [
    {img: "https://unsplash.com/photos/CUy6A3OZV4w/download?force=true&w=640", name: "Beach" },
    {img: "https://unsplash.com/photos/ToTurJAsAh0/download?force=true&w=640", name: "Fairs" },
    {img: "https://unsplash.com/photos/ULHxWq8reao/download?force=true&w=640", name: "Drinks" },
    {img: "https://unsplash.com/photos/rsV2M1lUFFU/download?force=true&w=640", name: "Food" },
    {img: "https://unsplash.com/photos/nWAlCB1tyvc/download?force=true&w=640", name: "Craft" },
    {img: "https://unsplash.com/photos/MxfcoxycH_Y/download?force=true&w=640", name: "Night Life" },
    {img: "https://unsplash.com/photos/10QkXxk0mVA/download?force=true&w=640", name: "Outdoor Sport" },
    {img: "https://unsplash.com/photos/mLMvIqtU4ww/download?force=true&w=640", name: "Entertainment" },
    {img: "https://unsplash.com/photos/S_bbqW4yQZA/download?force=true&w=640", name: "Sales" },
    {img: "https://unsplash.com/photos/CGAEZnMZzLk/download?force=true&w=640", name: "Holiday" },
    {img: "https://unsplash.com/photos/V3dHmb1MOXM/download?force=true&w=640", name: "Family" },
    {img: "https://unsplash.com/photos/m3th3rIQ9-w/download?force=true&w=640", name: "Theater" },
    {img: "https://unsplash.com/photos/9o8YdYGTT64/download?force=true&w=640", name: "School" },
    {img: "https://unsplash.com/photos/AZMmUy2qL6A/download?force=true&w=640", name: "Community"}
]