import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createStackNavigator} from "@react-navigation/stack";
import CartScreen from "../screens/CartScreen";
import CheckoutScreen from "../screens/CheckoutScreen";

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

class PurchaseStack extends Component {
    render() {
        const PurchaseStack = createStackNavigator();
        return (
            <PurchaseStack.Navigator>
                <PurchaseStack.Screen name="Cart" component={CartScreen}/>
                <PurchaseStack.Screen name="Checkout" component={CheckoutScreen} />
            </PurchaseStack.Navigator>
        );
    }
}

export default connect(
    mapStateToProps,
)(PurchaseStack);