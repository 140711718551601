import React from 'react';
import { View } from 'native-base';
import { H2, Icon } from 'native-base';
import {useTheme} from "@react-navigation/native";

const Missing = ({message}) => {
    const {colors} = useTheme();
    return (
        <View style={{alignItems: 'center'}}> 
            <View style={{borderRadius: 200, backgroundColor: colors.background, borderColor: '#14629D', borderWidth:2}}>
                <Icon style={{ fontSize: 75, color: 'white' }} name="help"/>
            </View>
            <View style={{paddingTop: 20}}>
                <H2>{message}</H2>
            </View>
        </View>
    )
}

export default Missing;
