
import React, { useEffect, useMemo, useState } from 'react';
import { Content, Button, Text } from 'native-base';
import ActivityCard from '../components/ActivityCard';
import { connect } from 'react-redux';
import { getEvents } from '../redux/actions/EventActions';
import { useTheme } from "@react-navigation/native";
import { eventSearch, loadEventsFromDB, saveMockEventsToDB } from '../api/EventDataApi'
import { getUserDataForProfile } from '../api/PersonDataAPI';
import { bindActionCreators } from 'redux';
import {
    Platform,
    Modal,
    RefreshControl,
    SafeAreaView,
    ScrollView,
    StyleSheet,
    View, ImageBackground, ActivityIndicator
} from "react-native";
import { SearchBar } from "react-native-elements";
import { getRandomRefreshMessage, getRandomSearchMessage } from "../utils";
import Icon from 'react-native-vector-icons/FontAwesome';
import Grid from "../components/Grid/Grid";
import { SearchCategories } from "../components/Grid/SearchCategories"
import { FlatGrid } from "react-native-super-grid";
import {buttonPress_SearchForEvent_EventsHome, viewPage_EventsHome} from "../utils/analytics";

const EventsHome = (props) => {
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    let [newMessage, setNewMessage] = useState(0);
    const [refreshing, setRefreshing] = React.useState(false);

    useEffect(() => {
        setLoading(true)
        props.actions.loadEventsFromDB();
        setLoading(false)
    }, []);

    const searchMessage = useMemo(() => {
        return getRandomSearchMessage()
    }, [newMessage]);

    useEffect(async () => {
        console.log("Here")
       // await viewPage_EventsHome();
    }, [])
    const refreshMessage = useMemo(() => {
        return getRandomRefreshMessage()
    }, [newMessage]);

    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
       
        setLoading(true)
        setNewMessage(newMessage++)
        setRefreshing(false)
        setLoading(false)
    }, []);

    const searchBarPlatform = useMemo(() => {
        const os = Platform.OS;
        if (os === 'web') {
            return 'default';
        }
        return os
    })
    useMemo(()=>{
        if(search){
            props.actions.eventSearch(search);
        }
    },[search]);

    const { colors, dark } = useTheme();
    const styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            flex: 1
        },
        textColor: {
            color: colors.text
        },
        backgroundColor: {
            backgroundColor: colors.background
        },
        fakeSearch: {
            backgroundColor: colors.card
        },
        gridView: {
            marginTop: 10,
            flex: 1,
        },
        itemContainer: {
            justifyContent: 'flex-end',
            borderRadius: 5,
            height: 150,
        },
        itemName: {
            fontSize: 16,
            color: colors.text,
            fontWeight: '600',

        },
        itemCode: {
            fontWeight: '600',
            fontSize: 12,
            color: '#fff',
        },
        image: {
            flex: 1,
            resizeMode: "cover",
            justifyContent: "center"
        },
    });

    const homeContent = useMemo(() => {
        return (
            <ScrollView
                keyboardDismissMode={'on-drag'}
                style={[styles.backgroundColor]}
                stickyHeaderIndices={[0]}
                indicatorStyle={dark ? "white" : "black"}
                refreshControl={
                    <RefreshControl
                        enabled
                        tintColor={colors.text}
                        titleColor={colors.text}
                        title={refreshMessage}
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                    />
                }
            >
                <View style={[styles.backgroundColor, { paddingBottom: 10 }]}>
                    <Button full transparent rounded style={[styles.fakeSearch, { backgroundColor: colors.searchBar, width: '100%' }]} onPress={async () => {
                        setIsVisible(true)
                        await buttonPress_SearchForEvent_EventsHome()
                    }}>
                        <Icon name='search' color={'#666666'} style={[{ fontSize: 20 }]} />
                        <Text style={[{ color: '#666666' }]}>{searchMessage}</Text>
                    </Button>
                </View>
                <Modal
                    animationType={'slide'}
                    onRequestClose={() => setIsVisible(false)}
                    visible={isVisible}
                >
                    <SafeAreaView style={[styles.container, styles.backgroundColor]}>
                        <Button full iconRight style={{ alignSelf: 'flex-end', marginRight: 10, paddingLeft: 10, paddingRight: 10 }} transparent onPress={() => setIsVisible(false)}>
                            <Icon name='close' color={colors.text} />
                        </Button>
                        <SearchBar
                            placeholder={searchMessage}
                            onChangeText={setSearch}
                            value={search}
                            platform={searchBarPlatform}
                            inputContainerStyle={{ backgroundColor: colors.card, color: colors.text }}
                            inputStyle={{ color: colors.text }}
                            containerStyle={styles.backgroundColor}
                        />
                        <Grid data={SearchCategories} />
                    </SafeAreaView>
                </Modal>
                <FlatGrid
                    itemDimension={450}
                    data={props.events}
                    style={styles.gridView}
                    spacing={1}
                    renderItem={({ item }, i) => (
                        <ActivityCard key={`event.eventName${i}`} event={item} ticketTypes={item?.ticketTypes} navigation={props.navigation} />
                    )}
                />
            </ScrollView>)
    }, [props, refreshing, loading, getEvents, isVisible, newMessage, search])

    return (

        <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === 'android' ? 25 : 0 }}>
            {loading ? <ActivityIndicator color={colors.primary} size="large" style={{ flex: 1 }} /> : homeContent}
        </SafeAreaView>
    );

}

const mapStateToProps = state => ({
    events: state.EventData.allEvents,
    userData: state.PersonData.userData,
    token: state.AuthData.userToken
});

const ActionCreators = Object.assign(
    {},
    { loadEventsFromDB },
    { getUserDataForProfile },
    { eventSearch }
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(EventsHome)


