
import React, {useEffect, useMemo, useState} from 'react';
import { Content, Icon } from 'native-base';
import ActivityCard from '../components/ActivityCard';
import { connect } from 'react-redux';
import { getEvents } from '../redux/actions/EventActions';
import { useTheme } from "@react-navigation/native";
import { loadEventsFromDB } from '../api/EventDataApi'
import {
    Button,
    Modal,
    RefreshControl,
    SafeAreaView,
    ScrollView, Text,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View
} from "react-native";
import {StyleSheet} from "react-native";
import { SearchBar} from "react-native-elements";
import {getRandomRefreshMessage, getRandomSearchMessage} from "../utils";
import {Platform} from "react-native";

const EventsHome = (props) => {
    const [search, setSearch] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    let [newMessage, setNewMessage] = useState(0);
    const [refreshing, setRefreshing] = React.useState(false);
    useEffect(() => {
        (async () => {
            const events = await loadEventsFromDB();
            props.getEvents(events);
        })();
    }, []);
    const searchMessage = useMemo(() => {
        return getRandomSearchMessage()
    }, [newMessage]);
    const refreshMessage = useMemo(() => {
        return getRandomRefreshMessage()
    }, [newMessage]);

    const onRefresh = React.useCallback(async () => {
        setRefreshing(true);
        await loadEventsFromDB().then((events) => {
            props.getEvents(events);
            setNewMessage(newMessage++)
            setRefreshing(false)
        });
    }, []);

    const searchBarPlatform = useMemo(()=>{
        const os = Platform.OS;
        if(os === 'web'){
            return 'default';
        }
        return os
    })


    const { colors, dark } = useTheme();
    const styles = StyleSheet.create({
        container: {
            alignItems: 'center',
            flex: 1
        },
        textColor: {
            color: colors.text
        },
        backgroundColor: {
            backgroundColor: colors.background
        }
    });
    const animationType = 'slide';

    return (
        <SafeAreaView>
            <ScrollView
                keyboardDismissMode={'on-drag'}
                style={styles.backgroundColor}
                stickyHeaderIndices={[0]}
                indicatorStyle={dark ? "white" : "black"}
                refreshControl={
                    <RefreshControl
                        enabled
                        tintColor={colors.text}
                        titleColor={colors.text}
                        title={refreshMessage}
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                    />
                }
            >
            <SearchBar
                placeholder={searchMessage}
                onChangeText={setSearch}
                value={search}
                platform={searchBarPlatform}
                inputContainerStyle={{backgroundColor:colors.card, color: colors.text}}
                inputStyle={{color: colors.text}}
                containerStyle={styles.backgroundColor}
            />
            <Content>

                {
                    props?.events?.map((event, i) => {
                        return (
                            <ActivityCard key={`event.eventName${i}`} event={event} ticketTypes={event?.ticketTypes} navigation={props.navigation} />
                        )
                    })}
            </Content>
            </ScrollView>
        </SafeAreaView>
    );

}

const mapStateToProps = state => ({
    events: state.EventData.allEvents,
});



export default connect(mapStateToProps, { getEvents })(EventsHome)


