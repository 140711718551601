import { FOCUS_EVENT, GET_EVENTS, GET_WEATHER } from '../constants';
import eventData from '../../mocks/event.json';


const initialState = [];

const eventReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_EVENTS:
            return {
                ...state,
                allEvents: action.payload
            };
        case FOCUS_EVENT:
            return {
                ...state,
                focusedEvent: action.payload
            };
        case GET_WEATHER:
            return {
                ...state,
                weahterInfo: action.payload
            };
        default:
            return state;
    }
}
export default eventReducer;