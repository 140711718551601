import React, {useState} from 'react';
import {connect} from 'react-redux';
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import common from "../text/Common.json";
import {Icon} from "native-base";
import ProfileStack from "./ProfileStack";
import EventStack from "./EventStack";
import HomeStack from "./HomeStack";
import {useTheme} from "@react-navigation/native";
function mapStateToProps(state) {
    return {
        cart: state.CartData.cart
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const ThemeColors = () => {
    const {colors} = useTheme()
    return colors;
}

const MenuIcon = ({focused, color, name}) => {
    const colors = ThemeColors();
    return <Icon  style={{color: focused ? color : colors.text}} name={name}/>
}

const TabNavigator = (props) => {
        //const [cartCount, setCartCount] = useState(0);
        const getCartSize = () => {
            if(props.cart.length >= 1){
                let count = 0;
                for(let i = 0; i < props.cart.length; i++){
                    count += props.cart[i].quantity;
                }
                if(count >= 99){
                    return '99+';
                }
                //this.setState({cartCount: count})
                return count
            }
        };
        const Tab = createBottomTabNavigator();
        const colors = ThemeColors();

        //FINDME this route should be set to home first if they are logged in
        return (
            <Tab.Navigator
                initialRouteName={"find_events"}
                tabBarOptions={{
                    style: {
                        backgroundColor: colors.tabBar
                    },
                    activeTintColor: colors.primary,
                    labelPosition: 'below-icon'
                }}>
                <Tab.Screen
                    name="Home"
                    component={HomeStack}
                    options={{
                        tabBarLabel: common.home,
                        tabBarIcon: ({color, focused}) => {
                            return (<MenuIcon focused={focused} color={color}  name={"home"}/>)
                        },}}
                />
                <Tab.Screen
                    name="find_events"
                    component={EventStack}
                    options={{
                        tabBarBadge: getCartSize(),
                        tabBarLabel: common.find_events,
                        tabBarIcon: ({focused, color}) => (<MenuIcon focused={focused} color={color} name={"search"}/>)
                    }}

                />
                {/*<Tab.Screen*/}
                {/*    component={TicketScreen}*/}
                {/*    name="myTickets"*/}
                {/*    options={{*/}
                {/*        tabBarLabel: common.tickets,*/}
                {/*        tabBarIcon: ({color, focused}) => (<MenuIcon focused={focused} color={color} name={"pricetags"}/>)*/}
                {/*    }}*/}
                {/*/>*/}
                <Tab.Screen
                    component={ProfileStack}
                    name="profile"
                    options={{
                        tabBarLabel: common.profile,
                        tabBarIcon: ({color, focused}) => (<MenuIcon focused={focused} color={color} name={"person"}/>)
                    }}

                />
            </Tab.Navigator>
        );
}

export default connect(
    mapStateToProps,
)(TabNavigator);