export const GET_EVENTS = 'GET_EVENTS';
export const FOCUS_EVENT = 'FOCUS_EVENT';
export const GET_WEATHER = "GET_WEATHER";
export const GET_PERSON = "GET_PERSON"; 
export const LOGIN_PERSON = "LOGIN_PERSON";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const RESTORE_TOKEN = "RESTORE_TOKEN";
export const GET_OWNED_EVENTS = "GET_OWNED_EVENTS";
export const SET_OWNED_EVENTS = "SET_OWNED_EVENTS";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const EMPTY_CART = "EMPTY_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const GET_STARRED_EVENTS = "GET_STARRED_EVENTS";
export const STAR_EVENT = "STAR_EVENT";
export const UNSTAR_EVENT = "UNSTAR_EVENT";