import React, {useMemo} from 'react';
import { Button, ListItem, Icon, Left, Body, Right } from 'native-base';
import {Linking, Text, StyleSheet, View } from 'react-native';
import common from '../text/Common.json';
import { makeAddress } from '../components/Address';
import Missing from './Errors/Missing';
import Clipboard from 'expo-clipboard';
import { connect } from 'react-redux';
import { focusEvent } from '../redux/actions/EventActions';
import { bindActionCreators } from 'redux';
import {useTheme} from "@react-navigation/native";
import {openMap} from "../utils";

const ContactCard = (props) => {
        const {colors} = useTheme();
        const styles = useMemo(() => {
            return StyleSheet.create({
                backgroundTheme: {
                    backgroundColor: colors.background ,
                },
                textTheme: {
                    color: colors.text
                }
            });
        })
        let phone, email, socialUrl, website, address;
        if(props?.hostDetails?.contact){
            const contactObject = props.hostDetails.contact;
            phone = contactObject.phone;
            email = contactObject.email;
            socialUrl = contactObject.facebookPage;
            website = contactObject.website;
        }
        if(props && props.location){
            address = makeAddress(props.location);
        }
        const placeCall = () => {
            Linking.openURL(`tel:${phone}`)
        }

        const sendEmail = () => {
            Linking.openURL(`mailto:${email}`)
        }

        const openSocial = () => {
            Linking.openURL(socialUrl)
        }

        const openWebsite = () => {
            Linking.openURL(website)
        }

        const copyToClipboard = (copyText) => {
            Clipboard.setString(copyText);
          };

        const locationComponent = (
            <ListItem icon onPress={() => openMap(address)} onLongPress={() => { copyToClipboard(address)}} style={styles.backgroundTheme}>
            <Left>
                <Button style={{ backgroundColor: "#E74B3C", fontSize: 4 }}>
                <Icon active name="navigate-outline" />
                </Button>
            </Left>
            <Body>
                <Text style={styles.textTheme}>{address}</Text>
            </Body>
            <Right>
                <Icon active name="arrow-forward" />
            </Right>
            </ListItem>
        );

        const phoneComponent = (
            <ListItem icon onPress={placeCall} onLongPress={() => { copyToClipboard(phone)}} style={styles.backgroundTheme}>
            <Left>
                <Button style={{ backgroundColor: "#27DE16" }}>
                <Icon active name="call-outline" />
                </Button>
            </Left>
            <Body>
                <Text style={styles.textTheme}>{phone}</Text>
            </Body>
            <Right>
                <Icon active name="arrow-forward" />
            </Right>
            </ListItem>
        );

        const emailComponent = (
            <ListItem icon onPress={sendEmail} onLongPress={() => { copyToClipboard(email)}} style={styles.backgroundTheme}>
                <Left>
                  <Button style={{ backgroundColor: "#0187CF" }}>
                    <Icon active name="mail-outline" />
                  </Button>
                </Left>
                <Body>
                  <Text style={styles.textTheme}>{email}</Text>
                </Body>
                <Right>
                    <Icon active name="arrow-forward" />
                </Right>
              </ListItem>
        );
        
        const socialComponent = (
            <ListItem icon onPress={openSocial} onLongPress={() => { copyToClipboard(socialUrl)}} style={styles.backgroundTheme}>
            <Left>
                <Button style={{ backgroundColor: "#3b5998", fontSize: 4 }}>
                <Icon active name="logo-facebook" />
                </Button>
            </Left>
            <Body>
                <Text style={styles.textTheme}>{common.facebook}</Text>
            </Body>
            <Right>
                <Icon active name="arrow-forward" />
            </Right>
            </ListItem>
        )

        const websiteComponent = (
            <ListItem icon onPress={openWebsite} onLongPress={() => { copyToClipboard(website)}} style={styles.backgroundTheme}>
                <Left>
                  <Button style={{ backgroundColor: "#5D5D5D", fontSize: 4 }}>
                    <Icon active name="link-outline" />
                  </Button>
                </Left>
                <Body>
                  <Text style={styles.textTheme}>{common.website}</Text>
                </Body>
                <Right>
                    <Icon active name="arrow-forward" />
                </Right>
              </ListItem>
        )
        return (
            <View style={styles.backgroundTheme}>
                {address ? locationComponent : <></>}
                {phone ? phoneComponent : <></>}
                {email ? emailComponent : <></>}
                {socialUrl ? socialComponent : <></>}
                {website ? websiteComponent : <></>}
                {!address && !phone && !email && !socialUrl && !website ? 
                <>
                   <Missing message={common.no_contact} />
                </>
                : <></>
                
                }
            </View>
        );
}


  
  export default (ContactCard);