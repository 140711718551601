export function refreshTextArray() {
    return [
        "🇺🇸 By the people, for the people 🇺🇸",
        "Getting great events in your area!",
        "Your community is counting on you",
        "We all have a need to belong to something.",
        "Recommend Local Life to a friend!",
        "In every community, there is work to be done.",
        "There is no power for change greater than a community discovering what it cares about.",
        "It's easy to make a buck. It's a lot tougher to make a difference.",
        "Civitas",
        "Jersey Strong",
        "Together we can get through it"
    ]
}

export function searchTextArray() {
    return [
        "An event is just a search away",
        "Get out there and attend and event",
        "Search for something to do ☺️",
        "Find great events in your area!",
        "Search for a way to jump into the community",
        "One small search for me, One big leap for the community",
        "Maybe we can find a festival with this search",
        "Search for some foodie events 🍔",
        "To an event and beyond",
        "Recommend Local life to a friend?",
        "Search for today's best events",
        "Search for great events near you",
        "Bored? Search for something!",
        "Search",
        "📞 Ring Ring an event is calling",
    ];
}