import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import EventReducer from '../reducers/EventReducer';
import PersonReducer from '../reducers/PersonReducer';
import AuthReducer from '../reducers/AuthReducer'
import CartReducer from "../reducers/CartReducer";
import thunk from 'redux-thunk';

const rootReducer = combineReducers(
    {
        EventData: EventReducer,
        PersonData: PersonReducer,
        AuthData: AuthReducer,
        CartData: CartReducer
    }
);

const configureStore = () => {
    return createStore(rootReducer,
        compose(applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    );
}
export default configureStore;