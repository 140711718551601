import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { createEvent, saveMockEventsToDB } from "../api/EventDataApi";
import { Container, Content, List, ListItem, Icon, Right, Body } from 'native-base';
import { Button, Text, TextInput, StyleSheet } from 'react-native';
import common from '../text/Common.json';
import { useTheme } from "@react-navigation/native";
import { signOutFromFirebase } from '../api/AuthDataApi';


function Profile(props) {
    let [eventObject, setEventObject] = useState({
        eventName: '',
        link: '',
        date: {
            start: '',
            end: ''
        },
        location: {
        },
        description: '',
        admins: [],
        images: [],
        host: {
            contact: {
            }
        },
        ticketTypes: [
        ]
    });
    const { colors } = useTheme();
    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                flex: 1,
                backgroundColor: colors.background,
                alignItems: 'center',
                justifyContent: 'center',
            },
            logo: {
                fontWeight: "bold",
                fontSize: 50,
                color: colors.text,
                marginBottom: 40
            },
            inputView: {
                width: "80%",
                backgroundColor: "#FFFFFF",
                borderRadius: 25,
                height: 50,
                marginBottom: 20,
                justifyContent: "center",
                padding: 20
            },
            inputText: {
                height: 50,
                color: "black"
            },
            forgot: {
                color: colors.text,
                fontSize: 11
            },
            loginText: {
                color: colors.text,
                fontSize: 20
            },
            signupBtn: {
                width: "80%",
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: -10
            },
            loginBtn: {
                width: "80%",
                backgroundColor: colors.primary,
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: 40,
                marginBottom: 10
            },
            forgotBtn: {
                width: "80%",
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: -20
            }
        })
    }, [useTheme, colors]);

    return (
        <Container>
            <Content>
                <List>
                    <ListItem>
                        <Body>
                            <Text>{common.add_card}</Text>
                        </Body>
                        <Right>
                            <Icon active name="arrow-forward" />
                        </Right>
                    </ListItem>
                    <ListItem>
                        <Body>
                            <Text>{common.purchase_history}</Text>
                        </Body>
                        <Right>
                            <Icon active name="arrow-forward" />
                        </Right>
                    </ListItem>
                    <ListItem>
                        <Body>
                            <Text>{common.account_info}</Text>
                        </Body>
                        <Right>
                            <Icon active name="arrow-forward" />
                        </Right>
                    </ListItem>
                    {props.authData.userToken ?
                        <ListItem onPress={() => { props.navigation.navigate('Admin') }}>
                            <Body>
                                <Text>{'Create Event'}</Text>
                            </Body>
                            <Right>
                                <Icon active name="arrow-forward" />
                            </Right>
                        </ListItem> :
                        <></>
                    }
                        <ListItem onPress={() => { saveMockEventsToDB() }}>
                            <Body>
                                <Text>{'Save Mock Events to DB'}</Text>
                            </Body>
                            <Right>
                                <Icon active name="arrow-forward" />
                            </Right>
                        </ListItem> :
                </List>
                <Button
                    title={common.sign_out}
                    onPress={() => {
                        props.dispatch(signOutFromFirebase())
                    }}
                />
            </Content>
        </Container>
    );
}

const mapStateToProps = state => {
    return ({
        personInfo: state.PersonData,
        authData: state.AuthData
    });
};



export default connect(mapStateToProps)(Profile);