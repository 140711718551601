import React, {Component} from 'react';
import {connect} from 'react-redux';
import {StyleSheet, TextInput, View} from 'react-native';
import {Button, Card, CardItem, Text, Picker} from "native-base";
import {addToCart} from "../redux/actions/CartActions";
import {bindActionCreators} from "redux";
import {makeDollar} from "../utils";
import common from '../text/Common.json'

function mapStateToProps(state) {
    return {};
}

const ActionCreators = Object.assign(
    {},
    {addToCart},
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

class TicketDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartTotal: 1
        }
    }


    render() {
        const {section} = this.props;
        return (
            <View style={styles.content}>
                <Card transparent>
                    <CardItem header>
                        <Text>{section.description}</Text>
                    </CardItem>
                    <CardItem >
                        <View style={styles.container}>
                            <View style={styles.textInput}>
                                <Text>{common.quantity}</Text>
                                <Picker
                                    mode="dropdown"
                                    selectedValue={this.state.cartTotal}
                                    style={styles.cartTotal}
                                    onValueChange={(itemValue, itemIndex) =>
                                        this.setState({cartTotal: itemValue})
                                    }>
                                    <Picker.Item label="1" value={1} />
                                    <Picker.Item label="2" value={2} />
                                    <Picker.Item label="3" value={3} />
                                    <Picker.Item label="4" value={4} />
                                    <Picker.Item label="5" value={5} />
                                    <Picker.Item label="6" value={6} />
                                    <Picker.Item label="7" value={7} />
                                    <Picker.Item label="8" value={8} />
                                    <Picker.Item label="9" value={9} />
                                    <Picker.Item label="10" value={10} />
                                    <Picker.Item label="11" value={11} />
                                    <Picker.Item label="12" value={12} />
                                    <Picker.Item label="13" value={13} />
                                    <Picker.Item label="14" value={14} />
                                    <Picker.Item label="15" value={15} />
                                    <Picker.Item label="16" value={16} />
                                    <Picker.Item label="17" value={17} />
                                    <Picker.Item label="18" value={18} />
                                    <Picker.Item label="19" value={19} />
                                    <Picker.Item label="20" value={20} />
                                </Picker>
                            </View>
                            {this.state.cartTotal > 0 ?
                                ( <Text>{makeDollar(section.price*this.state.cartTotal)}</Text>) : (<></>)
                            }
                            <View >
                                <Button onPress={() => {
                                    this.props.actions.addToCart(section, this.props.img, this.state.cartTotal)
                                }}>
                                    <Text>{common.add_to_cart.toUpperCase()}</Text>
                                </Button>
                            </View>
                        </View>
                    </CardItem>
                </Card>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
    },
    cartTotal: {
        fontSize: 30,
        lineHeight: 20,
        height: 50,
        width: 100,
        borderColor: 'gray',
        borderWidth: 1,
        textAlign: 'center'
    },
    content: {
        width: '100%',
        marginTop: -10,
        backgroundColor: '#fff',
    },
    textInput: {
       padding: 10
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketDetails);