/* eslint-disable */
import React from 'react';
import 'react-native-gesture-handler';
import {DarkTheme, DefaultTheme, NavigationContainer} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Provider } from 'react-redux';
import 'intl';
import 'intl/locale-data/jsonp/en';

import configureStore from './redux/store/configureStore';
import TabNavigator from "./Navigators/TabNavigator";
import PurchaseStack from "./Navigators/PurchaseStack";
import ProfileStack from "./Navigators/ProfileStack";
import EventStack from "./Navigators/EventStack";
import firebase from "firebase";
import {getBrandPrimary} from "./utils";
import Toast from "react-native-fast-toast";

import {SafeAreaView} from "react-native";
const store = configureStore();

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyDBiWB1qppAl-fXiiFwcjkFDx1FaGBNWz4',
  authDomain: 'locallife-d80e8.firebaseapp.com',
  databaseURL: 'https://locallife-d80e8.firebaseio.com',
  projectId: 'locallife-d80e8',
  storageBucket: 'locallife-d80e8.appspot.com',
  messagingSenderId: 'sender-id',
  appId: '1:732861838859:web:7f2c52232e8f5b8586390e',
  measurementId: "G-S6GJMBSBEF"
};

firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

const RootStack = createStackNavigator();
export default class App extends React.Component {

  render() {
    const LocalLifeDarkTheme = {
      ...DarkTheme,
      colors: {
        ...DarkTheme.colors,
        card: '#242526',
        background: '#18191A',
        primary: getBrandPrimary(),
        tabBar: '#3B3B3B',
        searchBar: 'rgb(36, 37, 38)'
      },
    };

    const LocalLifeLightTheme = {
      ...DefaultTheme,
      colors: {
        ...DefaultTheme.colors,
        primary: getBrandPrimary(),
        searchBar: 'rgb(227,227,227)'
      },
    };

    return (
          <Provider store = { store }>
              <Toast placement="top" ref={(ref) => global['toast'] = ref} />
            <NavigationContainer theme={LocalLifeDarkTheme}>
              <RootStack.Navigator>
                <RootStack.Screen options={{headerShown: false, title: 'Back To Previous Screen' }} name="HomeRoot" component={TabNavigator} />
                <RootStack.Screen options={{headerShown: false}} name="PurchaseRoot" component={PurchaseStack} />
                <RootStack.Screen options={{headerShown: false}} name="ProfileRoot" component={ProfileStack} />
                <RootStack.Screen options={{headerShown: false}} name="EventRoot" component={EventStack} />
              </RootStack.Navigator>
            </NavigationContainer>
          </Provider>
    )
  }
}