import React from 'react';
import {connect} from 'react-redux';
import {createStackNavigator} from "@react-navigation/stack";
import HomeScreen from "../screens/HomeScreen";
import DetailsScreen from "../screens/DetailsScreen";
import {useTheme} from "@react-navigation/native";
import LocalLifeLogoText from "../components/LocalLifeLogoText";
import BuzzShocked from "../components/Errors/BuzzShocked";
import {loginCheckAsync} from "../utils";

function mapStateToProps(state) {
    return ({
        loading: state.AuthData.isLoading,
        signedOut: state.AuthData.isSignout,
        token: state.AuthData.userToken
    });
}

function mapDispatchToProps(dispatch) {
    return {};
}


function isLoggedIn(token) {
    //TODO verify user token with Firebase on restore
    return token !== null && token !== undefined;
}

function HomeStack(props) {
    const Stack = createStackNavigator();
    const theme = useTheme();
    return (
        <Stack.Navigator>
        {
            isLoggedIn(props.token) ?
                <Stack.Screen
                    name="Home"
                    component={HomeScreen}
                    options={{ headerTitle: () => <LocalLifeLogoText /> }}
                /> :
                <Stack.Screen
                    name="Details"
                    component={BuzzShocked}
                />
        }



        </Stack.Navigator>
    );


}

export default connect(
    mapStateToProps,
)(HomeStack);