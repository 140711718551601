import React from 'react';
import { Platform, StyleSheet, KeyboardAvoidingView, View, TouchableOpacity, ScrollView, Image, ActivityIndicator, TextInput, Alert } from 'react-native';
import { MaterialIcons, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import {connect} from "react-redux";
import {makeDollar} from "../utils";
import {updateCart} from "../redux/actions/CartActions";
import {setOwnedEvents} from "../redux/actions/PersonActions";
import {bindActionCreators} from "redux";
import {Button, Text} from 'native-base';
import {getBrandPrimary} from "../utils";
import common from '../text/Common.json';

function mapStateToProps(state) {
    return {
        cart: state.CartData.cart
    };
}

const ActionCreators = Object.assign(
    {},
    {updateCart},
    {setOwnedEvents}
);

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});


class Cart extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectAll: true,
            cartItemsIsLoading: false,
        }
    }

    selectHandler = (index, value) => {
        const newItems = [...this.props.cart]; // clone the array
        newItems[index]['checked'] = value == 1 ? 0 : 1; // set the new value
        this.props.actions.updateCart(newItems); // set new state
    }

    selectHandlerAll = (value) => {
        const newItems = [...this.props.cart]; // clone the array
        newItems.map((item, index) => {
            newItems[index]['checked'] = value == true ? 0 : 1; // set the new value
        });
        this.setState({ cartItems: newItems, selectAll: (value == true ? false : true) }); // set new state
    }

    deleteHandler = (index) => {
        Alert.alert(
            'Are you sure you want to delete this item from your cart?',
            '',
            [
                {text: common.cancel, onPress: () => console.log('Cancel Pressed'), style: 'cancel'},
                {text: common.delete, onPress: () => {
                        let updatedCart = this.props.cart; /* Clone it first */
                        updatedCart.splice(index, 1); /* Remove item from the cloned cart state */
                        this.props.actions.updateCart(updatedCart) /* Update the state */
                        this.setState({ cartItems: updatedCart }); // set new state
                    }},
            ],
            { cancelable: false }
        );
    }

    quantityHandler = (action, index) => {
        const newItems = [...this.props.cart]; // clone the array

        let currentQty = newItems[index]['quantity'];

        if(action == 'more'){
            newItems[index]['quantity'] = currentQty + 1;
        } else if(action == 'less'){
            newItems[index]['quantity'] = currentQty > 1 ? currentQty - 1 : 1;
        }

        this.props.actions.updateCart(newItems)
        this.setState({ cartItems: newItems }); // set new state
    }

    subtotalPrice = () => {
        const cartItems = this.props.cart;
        if(cartItems){
            return cartItems.reduce((sum, item) => sum + (item.checked == 1 ? item.quantity * item.event.price : 0), 0 );
        }
        return 0;
    }

    cartEmpty = () => {
        return this.props.cart && this.props.cart.length === 0;
    }



    render() {
        const styles = StyleSheet.create({
            centerElement: {justifyContent: 'center', alignItems: 'center'},
        });

        const {cartItemsIsLoading, selectAll } = this.state;



        return (
            <KeyboardAvoidingView  behavior={Platform.OS == 'ios' ? 'padding' : 'height'} style={{flex: 1, backgroundColor: '#f6f6f6'}}>
                {cartItemsIsLoading ? (
                    <View style={[styles.centerElement, {height: 300}]}>
                        <ActivityIndicator size="large" color="#ef5739" />
                    </View>
                ) : (
                    <ScrollView>
                        {this.props.cart && this.props.cart.map((item, i) => (
                            <View key={i} style={{flexDirection: 'row', backgroundColor: '#fff', marginBottom: 2, height: 120}}>
                                <View style={[styles.centerElement, {width: 60}]}>
                                    <TouchableOpacity style={[styles.centerElement, {width: 32, height: 32}]} onPress={() => this.selectHandler(i, item.checked)}>
                                        <Ionicons name={item.checked == 1 ? "ios-checkmark-circle" : "ios-checkmark-circle-outline"} size={25} color={item.checked == 1 ? getBrandPrimary() : "#aaaaaa"} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{flexDirection: 'row', flexGrow: 1, flexShrink: 1, alignSelf: 'center'}}>
                                    <TouchableOpacity onPress={() => {/*this.props.navigation.navigate('ProductDetails', {productDetails: item})*/}} style={{paddingRight: 10}}>
                                        <Image source={{uri: item.image}} style={[styles.centerElement, {height: 60, width: 60, backgroundColor: '#eeeeee'}]} />
                                    </TouchableOpacity>
                                    <View style={{flexGrow: 1, flexShrink: 1, alignSelf: 'center'}}>
                                        <Text numberOfLines={1} style={{fontSize: 18}}>{item.event.parentName + ": " + item.event.name}</Text>
                                        <Text numberOfLines={1} style={{color: '#333333', marginBottom: 10}}>{makeDollar(item.quantity * item.event.price)}</Text>
                                        <View style={{flexDirection: 'row'}}>
                                            <TouchableOpacity onPress={() => this.quantityHandler('less', i)} style={{ borderWidth: 1, borderColor: '#000000' }}>
                                                <MaterialIcons name="remove" size={22} color="#000000" />
                                            </TouchableOpacity>
                                            <Text style={{ borderTopWidth: 1, borderBottomWidth: 1, borderColor: '#000000', paddingHorizontal: 7, paddingTop: 3, color: '#000000', fontSize: 13 }}>{item.quantity}</Text>
                                            <TouchableOpacity onPress={() => this.quantityHandler('more', i)} style={{ borderWidth: 1, borderColor: '#000000' }}>
                                                <MaterialIcons name="add" size={22} color="#000000" />
                                            </TouchableOpacity>
                                        </View>
                                    </View>

                                </View>
                                <View style={[styles.centerElement, {width: 60}]}>
                                    <TouchableOpacity style={[styles.centerElement, {width: 32, height: 32}]} onPress={() => this.deleteHandler(i)}>
                                        <Ionicons name="md-trash" size={25} color="#ee4d2d" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        ))}
                    </ScrollView>
                )}

                {!cartItemsIsLoading &&
                <View style={{backgroundColor: '#fff', borderTopWidth: 2, borderColor: '#f6f6f6', paddingVertical: 5}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.centerElement, {width: 60}]}>
                            <View style={[styles.centerElement, {width: 32, height: 32}]}>
                                <MaterialCommunityIcons name="ticket" size={25} color="#f0ac12" />
                            </View>
                        </View>
                        <View style={{flexDirection: 'row', flexGrow: 1, flexShrink: 1, justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text>{common.voucher}</Text>
                            <View style={{paddingRight: 20}}>
                                <TextInput
                                    style={{paddingHorizontal: 10, backgroundColor: '#f0f0f0', height: 25, borderRadius: 4}}
                                    placeholder={common.voucher_prompt}
                                    value={''}
                                    onChangeText={(searchKeyword) => {

                                    } }
                                />
                            </View>
                        </View>
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <View style={[styles.centerElement, {width: 60}]}>
                            <TouchableOpacity style={[styles.centerElement, {width: 32, height: 32}]} onPress={() => this.selectHandlerAll(selectAll)}>
                                <Ionicons name={selectAll == true ? "ios-checkmark-circle" : "ios-checkmark-circle-outline"} size={25} color={selectAll == true ? getBrandPrimary() : "#aaaaaa"} />
                            </TouchableOpacity>
                        </View>
                        <View style={{flexDirection: 'row', flexGrow: 1, flexShrink: 1, justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text>{common.select_all}</Text>
                            <View style={{flexDirection: 'row', paddingRight: 20, alignItems: 'center'}}>
                                <Text style={{color: '#8f8f8f'}}>{common.subtotal}</Text>
                                <Text>{makeDollar(this.subtotalPrice())}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{flexDirection: 'row', justifyContent: 'flex-end', height: 50, paddingRight: 20, alignItems: 'center'}}>
                        <Button primary disabled={this.cartEmpty() ? true : false} onPress={() => this.props.navigation.navigate('Checkout')}>
                            <Text style={{color: '#ffffff', textAlign: 'center'}}>{common.checkout}</Text>
                        </Button>
                    </View>
                </View>
                }
            </KeyboardAvoidingView>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart);