import React, { Component } from 'react';
import Accordion from 'react-native-collapsible/Accordion';
import {View, Text, StyleSheet} from 'react-native';

import Constants from 'expo-constants';
import {Icon, Card, CardItem, Body, Button} from 'native-base';
import {TextInput} from 'react-native';
import {addToCart} from "../redux/actions/CartActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import TicketDetails from "./TicketDetails";
import {makeDollar} from "../utils";


class TicketDetailsAccordian extends Component {
    state = {
        activeSections: []
    };


    _renderHeader = (section, index) => {
        const name = this.state.activeSections.indexOf(index) >= 0 ? "chevron-up" : "chevron-down";
        return (
            <View style={styles.header}>
                <View style={styles.headerTextContianer}>
                    <Text style={styles.headerText}>
                        {section.name + ": " + makeDollar(section.price)}
                    </Text>

                    <Icon style={styles.headerIcon} name={name}/>
                </View>
            </View>
        );
    };

    _renderContent = section => {
        return (
            <TicketDetails section={section} img={this.props.img}/>
        );
    };

    _updateSections = activeSections => {
        this.setState({ activeSections });
    };



    render() {
        return (
            <Accordion
                sections={this.props.types}
                activeSections={this.state.activeSections}
                renderHeader={this._renderHeader}
                renderContent={this._renderContent}
                onChange={this._updateSections}
                expandMultiple
            />
        );
    }
}

const mapStateToProps = state => ({
    event: state.EventData.focusedEvent,
});

const ActionCreators = Object.assign(
    {},
    {addToCart},
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetailsAccordian)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F5FCFF',
        paddingTop: Constants.statusBarHeight,
        alignContent: 'flex-end'
    },
    title: {
        fontSize: 22,
        fontWeight: '300',
        marginBottom: 20,
    },
    header: {
        backgroundColor: '#e0e0e0',
        padding: 20,
        display: "flex",
        alignContent: 'flex-end'
    },
    headerText: {
        fontSize: 16,
        fontWeight: '500',
    },
    headerIcon: {
        textAlign: 'right',
        marginTop: -25
    },
    cartTotal: {
        fontSize: 35,
        lineHeight: 35,
        height: 40,
        borderColor: 'gray',
        borderWidth: 1
    },
    content: {
        width: '100%',
        marginTop: -10,
        backgroundColor: '#fff',
    },
    active: {
        backgroundColor: 'rgba(255,255,255,1)',
    },
    inactive: {
        backgroundColor: 'rgba(245,252,255,1)',
    },
    selectors: {
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    selector: {
        backgroundColor: '#F5FCFF',
        padding: 10,
    },
    activeSelector: {
        fontWeight: 'bold',
    },
    selectTitle: {
        fontSize: 14,
        fontWeight: '500',
        padding: 10,
    },
    multipleToggle: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: 30,
        alignItems: 'center',
    },
    multipleToggle__title: {
        fontSize: 16,
        marginRight: 8,
    },
});