import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginScreen from "../screens/LoginScreen";
import common from "../text/Common.json";
import { createStackNavigator } from "@react-navigation/stack";
import { bindActionCreators } from "redux";
import Profile from "../screens/ProfileScreen";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { restoreToken } from '../redux/actions/AuthActions';
import SignUpScreen from '../screens/SignUpScreen';
import AdminScreen from '../screens/AdminScreen';
import {loginCheckAsync} from "../utils";

class ProfileStack extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            userToken: this.props.token
        }

    }

    render() {
        const Stack = createStackNavigator();
        return (
            <Stack.Navigator>
                {this.isLoggedIn() ? (
                    <Stack.Screen
                        name="profileSettings"
                        component={Profile}
                        options={{ title: common.account_info }}
                    />
                ) : (
                    <>
                        <Stack.Screen
                            name="login"
                            component={LoginScreen}
                            options={{ title: common.login, headerShown: false }}
                        />
                        <Stack.Screen name="Profile" component={LoginScreen} />
                        <Stack.Screen name="SignUp" component={SignUpScreen} />
                        <Stack.Screen name="Admin" component={AdminScreen} />
                    </>
                )}
            </Stack.Navigator>
        );
    }

    isLoggedIn() {
        //TODO verify user token with Firebase on restore
        return this.props.token !== null && this.props.token !== undefined;
    }
}

const mapStateToProps = state => {
    return ({
        loading: state.AuthData.isLoading,
        signedOut: state.AuthData.isSignout,
        token: state.AuthData.userToken
    })
};

const ActionCreators = Object.assign(
    {},
    { restoreToken }
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileStack);