import React from 'react';
import common from '../text/Common.json';
import {Text, Button} from 'react-native'

const RegisterButton = ({capacity, full, navigation, itemToadd, addEventToPerson}) => {
    return (
        <Button
            disabled={capacity === 0}
            title={common.register}
            onPress={() => {
                if(addEventToPerson && itemToadd){
                    addEventToPerson([itemToadd]);
                }
                toast.show(common.event_added, { type: "success" })
            }}
            style={{position: "absolute", bottom: 0, top: 0}}
            full={full ? full : ""}
        >
        </Button>
    );
}
export default RegisterButton;