import {
    GET_PERSON, LOGIN_PERSON, GET_OWNED_EVENTS,
    SET_OWNED_EVENTS, STAR_EVENT, UNSTAR_EVENT, GET_STARRED_EVENTS
} from '../constants';

const initialState = {
    userData: {
        starredEvents: [],
    }
};

const personReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PERSON:
            return {
                ...state,
                userData: action.payload
            };
        case LOGIN_PERSON:
            return {
                ...state,
                personData: action.payload
            };
        case GET_OWNED_EVENTS:
            return {
                ...state,
                ownedEvents: state.ownedEvents
            }
        case SET_OWNED_EVENTS:
            if (state.ownedEvents) {
                return {
                    ...state,
                    ownedEvents: [...state.ownedEvents, ...action.payload]
                }
            }
        case STAR_EVENT:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    starredEvents: [...state.userData.starredEvents, action.payload]
                }
            }
        case UNSTAR_EVENT:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    starredEvents: state.userData.starredEvents.filter(event => event !== action.payload)
                }
            }
        default:
            return state;
    }
}
export default personReducer;