import React from 'react';
import Address from './Address';
import { H1, Grid, Row, View } from 'native-base';
import {makeDollar} from "../utils";
import {useTheme} from "@react-navigation/native";
import common from '../text/Common.json'
const EventHeader = ({name, location, price, link}) => {
    const {colors} = useTheme();
    let priceComponent = price !== false ? (
        <View style={{flex: 1, alignItems: 'flex-end', paddingTop: 9}}>
            <H1 style={{color: colors.text}}>
            {
                !price || price === 0  ? common.free : makeDollar(price)
            }
              </H1>
        </View>) : <></>
    return (
        <Grid>
            <Row style={{ height: 30 }}>
                {name ? (<H1 style={{fontWeight:"700", color: colors.text}}>{name}</H1>) : <></> }
                {priceComponent}
            </Row>
            <Row style={{ height: 20, width:1000000 }}>
            {location ?
                <Address link={link} address={location}></Address>
                :
                <></>
            }
            </Row>
        </Grid>
    );
}

export default EventHeader;