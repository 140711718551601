import {ADD_TO_CART, EMPTY_CART, UPDATE_CART } from '../constants';

export function addToCart(event, image, quantity) {
    return {
        type: ADD_TO_CART,
        payload: {
            event: event,
            image:image,
            quantity:quantity,
            checked: 1
        }
    }
}

export function removeFromCart(event) {
    return {
        type: REMOVE_FROM_CART,
        payload: null
    }
}

export function emptyCart() {
    return {
        type: EMPTY_CART,
        payload: null
    }
}

export function updateCart(cart) {
    return {
        type: UPDATE_CART,
        payload: cart
    }
}