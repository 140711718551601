import React, {useMemo} from 'react';
import {useTheme} from "@react-navigation/native";
import common from "../text/Common.json";
import {Linking, View, useWindowDimensions, Button } from "react-native";
import CalendarCard from "./CalendarCard";
import HTML from "react-native-render-html";

const Details = ({ description, date, link }) =>  {
    const {colors} = useTheme();
    return (
        <View style={{paddingBottom: 80, paddingRight: 10, paddingLeft: 10, flex:1}}>
            <CalendarCard date={date}/>
            <HTML source={{ html: description }} contentWidth={useWindowDimensions().width} baseFontStyle={{color:colors.text}} />
            {
                link ?
                    <Button
                        title={common.more_info}
                        style={{backgroundColor:"transparent"}}
                        onPress={() => {
                            if(link){
                                Linking.openURL(link)
                            }
                        }}>
                    </Button>
                        :
                    <></>
            }

        </View>
    )
}

export default Details;