
import React, {useMemo, useState} from 'react';
import {
    H1,
    Tabs,
    ScrollableTab,
    Tab, Content
} from 'native-base';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {useTheme} from "@react-navigation/native";
import {ScrollView, StyleSheet, View} from "react-native";
import BuzzShocked from "../components/Errors/BuzzShocked";
import common from "../text/Common.json";
import {getOwnedEvents} from "../redux/actions/PersonActions";
import ActivityCard from "../components/ActivityCard";
import { loadEventById } from '../api/EventDataApi';

const HomeScreen = (props) => {
    const { colors } = useTheme();
    const loggedIn = props.isLoggedIn;
    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                flex: 1,
                backgroundColor: colors.background,
                paddingTop: 20,
                paddingLeft: 20
            },
            error: {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            },
            errorMainText:{
                fontSize: 40,
                color: colors.text,
                fontWeight:"700",
                textAlign: 'center'
            },
            errorSubtext : {
                fontSize: 20,
                color: colors.text,
                textAlign: 'center'
            },
            backgroundColor: {
                backgroundColor: colors.background
            },
            tabsView: {
              flex: 1
            },
            textColor: {
                color: colors.text
            }
        })
    }, [useTheme, colors]);

    function HomeScreenLoggedIn() {
        return (
            <>
                <H1 style={{fontWeight:"700", color: colors.text,  padding: 20}}>Your Calendar</H1>
                <Tabs backgroundColor={colors.background}  style={[styles.backgroundColor,styles.tabsView]} renderTabBar={()=> <ScrollableTab backgroundColor={colors.background} />}>
                    <Tab style={styles.backgroundColor} tabStyle={[styles.backgroundColor,,styles.tabsView]} textStyle={styles.textColor} activeTabStyle={styles.backgroundColor} heading={common.upcoming}>
                        <ScrollView>
                            {props?.starredEvents?.map(eventId => {
                                //TODO we cant guarantee that when there are real events props.allEvents will have the starred ones
                                // but we also shouldn't be calling for all events at once, but want to save calls for now and not use loadEventById
                                const event = props.allEvents.filter(event => event.id === eventId)[0];
                                return (
                                    <ActivityCard ownedEvents key={event.id} event={event} ticketTypes={event.ticketTypes} navigation={props.navigation}/>
                                )
                            })}
                        </ScrollView>

                    </Tab>
                    <Tab style={styles.backgroundColor} tabStyle={styles.backgroundColor} textStyle={styles.textColor} activeTabStyle={styles.backgroundColor} heading={common.starred}>
                        <ScrollView>
                            {props?.starredEvents?.map(eventId => {
                                const event = props.allEvents.filter(event => event.id === eventId)[0];
                                return (
                                    <ActivityCard starredEvents key={event.id} event={event} ticketTypes={event.ticketTypes} navigation={props.navigation}/>
                                )
                            })}
                        </ScrollView>
                    </Tab>
                    <Tab style={styles.backgroundColor} tabStyle={styles.backgroundColor} textStyle={styles.textColor} activeTabStyle={styles.backgroundColor} heading={common.past}>

                    </Tab>
                </Tabs>
            </>
        );
    }

    return (
        loggedIn ? <HomeScreenLoggedIn /> : <BuzzShocked />
    );

}

const mapStateToProps = state => ({
    allEvents: state.EventData.allEvents,
    isLoggedIn: state.AuthData.userToken,
    starredEvents: state.PersonData.userData.starredEvents
});

const ActionCreators = Object.assign(
    {},
    {getOwnedEvents}
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
