import {Image, StyleSheet, View} from "react-native";
import {Text} from "native-base";
import React, {useMemo, useState} from "react";
import {useTheme} from "@react-navigation/native";

const BuzzShocked = () => {
    const { colors } = useTheme();
    const styles = useMemo(() => {
        return StyleSheet.create({
            error: {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            },
            errorMainText:{
                fontSize: 40,
                color: colors.text,
                fontWeight:"700",
                textAlign: 'center'
            },
            errorSubtext : {
                fontSize: 20,
                color: colors.text,
                textAlign: 'center'
            }
        })
    }, [useTheme, colors]);
    return (
        <View style={styles.error}>
            <Image
                style={{width: 300, height: 200}}
                source={require('../../assets/Buzz.gif')}
            />
            <Text style={styles.errorSubtext}> 😱 You are not signed in! 😱</Text>
            <View style={{width: '90%'}}>
                <Text style={styles.errorMainText}>Find awesome events</Text>
                <Text style={styles.errorSubtext}> In your community sponsored by your community</Text>
            </View>
        </View>
    );
}

export default BuzzShocked;