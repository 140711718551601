import {Image} from "react-native";
import React from "react";

const LocalLifeLogoText = () => {
    return (<Image
        style={{width: 200, height: 100}}
        source={require('../assets/LocalLifeText.png')}
    />);
}

export default LocalLifeLogoText;