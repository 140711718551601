import React, {Component} from 'react';
import {connect} from 'react-redux';
import {KeyboardAvoidingView, Platform} from 'react-native';
import {Button, Text} from "native-base";
import DismissableKeyboard from "../components/DismissableKeyboard";
import {emptyCart} from "../redux/actions/CartActions";
import {setOwnedEvents} from "../redux/actions/PersonActions";
import {bindActionCreators} from "redux";
import {StackActions, TabActions} from '@react-navigation/native';

function mapStateToProps(state) {
    return {
        cart: state.CartData.cart
    };
}
const ActionCreators = Object.assign(
    {},
    {emptyCart},
    {setOwnedEvents}
);

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});
class CheckoutScreen extends Component {


    render() {
        const jumpToAction = TabActions.jumpTo('myTickets');
        return (
            <DismissableKeyboard>
                <KeyboardAvoidingView  behavior={Platform.OS == 'ios' ? 'padding' : 'height'} style={{flex:1}}>


                        <Button block style={{marginTop: 20}} onPress={()=> {
                            this.props.actions.setOwnedEvents(this.props.cart);
                            if(this.props.navigation.canGoBack()) {
                                this.props.navigation.dispatch(StackActions.popToTop());
                            }

                            this.props.actions.emptyCart()
                            this.props.navigation.dispatch(jumpToAction)

                        }}>
                            <Text>Purchase</Text>
                        </Button>
                </KeyboardAvoidingView>
            </DismissableKeyboard>

        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutScreen);