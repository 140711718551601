import { FOCUS_EVENT, GET_EVENTS, GET_WEATHER } from '../constants';
import weather from '../../mocks/weather.json';

export function getEvents(eventResponse) {
    const mappedEvents = [];
    eventResponse.forEach(event => {
        const eventData = event.data();
        const mappedEvent = { ...eventData };
        mappedEvents.push(mappedEvent);
    });
    return {
        type: GET_EVENTS,
        payload: mappedEvents
    }
}

export function focusEvent(clickedEvent) {
    return {
        type: FOCUS_EVENT,
        payload: clickedEvent
    }
}

export function getWeather() {
    const getWeatherFromApi = () => weather;
    
    return {
        type: GET_WEATHER,
        payload: getWeatherFromApi()
    }
}