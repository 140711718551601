import firebase from 'firebase';

// **************************************************************
// **************************************************************
// **************************************************************
// ************************ Event Home **************************
// **************************************************************
// **************************************************************
// **************************************************************
export async function viewPage_EventsHome() {
    firebase.analytics().logEvent('screen_view', {
        name: 'eventsHomeView',
        screen: 'EventsHome',
        purpose: 'Open a event to see more details on events',
    });
}

export async function buttonPress_ViewDetails_EventsHome() {
    firebase.analytics().logEvent('openEvent', {
        name: 'openEvent',
        screen: 'EventsHome',
        purpose: 'Open a event to see more details on events',
    });
}

export async function buttonPress_FavoriteItem_EventsHome() {
    firebase.analytics().logEvent('favoriteEvent', {
        name: 'favoriteEvent',
        screen: 'EventsHome',
        purpose: 'Favorite an event to save it for later',
    });
}

export async function buttonPress_SearchForEvent_EventsHome() {
    firebase.analytics().logEvent('searchEvent', {
        name: 'searchEvent',
        screen: 'EventsHome',
        purpose: 'Favorite an event to save it for later',
    });
}

// **************************************************************
// **************************************************************
// **************************************************************
// ************************ Login  ******************************
// **************************************************************
// **************************************************************
// **************************************************************
