import {SIGN_OUT, SIGN_IN, RESTORE_TOKEN} from '../constants';

const initialState =  {
            isLoading: true,
            isSignout: false,
            userToken: null
        };

const AuthReducer = (state = initialState, action) => {
    switch(action.type) {
        case SIGN_OUT:
            return {
                ...state,
                isSignout: true,
                userToken: null,
            };
        case SIGN_IN:
            return {
                ...state,
                isSignout: false,
                userToken: action.payload,
            };
        case RESTORE_TOKEN:
            return {
                ...state,
                userToken: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default AuthReducer;