/* eslint-disable */
import React, {useMemo, useState} from 'react';
import {Dimensions, Image, Platform, ScrollView, StyleSheet, View} from 'react-native';
import {Fab, Button, Icon} from 'native-base';
import EventHeader from '../components/EventHeader';
import ImageCarousel from '../components/ImageCarousel';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Details from '../components/Details';
import common from '../text/Common.json';
import ContactCard from '../components/ContactCard'
import Tickets from '../components/TicketDetailsAccordian';
import {useTheme} from "@react-navigation/native";
import OwnerCard from "../components/OwnerCard";
import {setOwnedEvents} from "../redux/actions/PersonActions";
import RegisterButton from "../components/RegisterButton";
import {TabView, SceneMap, TabBar} from 'react-native-tab-view';

const DetailsScreen = (props) => {
    const [active, setActive] = useState(false);
    const {colors} = useTheme();
    const {width: screenWidth} = Dimensions.get('window');
    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                flex: 1
            },
            backgroundColor: {
                backgroundColor: colors.background
            },
            textColor: {
                color: colors.text
            }
        });
    })
    const {eventName, location, description, host , date} = props.event;
    let {images} = props.event;
    let imageComponent = <></>;
    if(images.length !== 1 && Platform.OS !== 'web'){
        imageComponent = (
            <ImageCarousel
                imgs={images.map((img) => {return {illustration: img}})}
            />
        )
    } else {
        imageComponent = <Image source={{ uri:images[0] }} width={100}  height={100} style={{marginBottom: 10, flex: 1}}/>
    }

    const DetailsTab = () => (
        <Details description={description} date={props?.event?.date}/>
    );

    const ContactTab = () => (
        <ContactCard hostDetails={props?.event?.host}/>
    );

    const TicketsTab = () => (
        <></>
    );

    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: 'details', title: 'Details' },
        { key: 'contact', title: 'Contact' },
        { key: 'tickets', title: 'Tickets' },
    ]);

    const renderScene = SceneMap({
        details: DetailsTab,
        contact: ContactTab,
        tickets: TicketsTab,
    });
    return (
            <ScrollView style={styles.container}>
                {imageComponent}
                <View style={{ paddingLeft: 17, paddingRight: 17, paddingBottom: 10}}>
                    <EventHeader name={eventName} location={location} price={false} link/>
                </View>
                {props?.event?.host ? <OwnerCard host={host}/> : <></>}

                <TabView
                    navigationState={{ index, routes }}
                    renderScene={renderScene}
                    onIndexChange={setIndex}
                    initialLayout={{ width: screenWidth, height: 500 }}
                    renderTabBar={props => <TabBar {...props} style={{backgroundColor:colors.background}} />}
                />
                <View style={{ backgroundColor:colors.card, padding: 10, width: '100%'}}>
                    <RegisterButton
                        navigation={props.navigation}
                        itemToadd={props.event}
                        full
                        addEventToPerson={props.actions.setOwnedEvents}
                    />
                </View>
                <Fab
                    active={active}
                    direction="down"
                    containerStyle={styles.fab}
                    style={{ backgroundColor: '#5067FF' }}
                    position="topRight"
                    onPress={() => setActive(!active)}>
                    <Icon name="share" />
                    <Button style={{ backgroundColor: '#34A34F' }}>
                        <Icon name="logo-whatsapp" />
                    </Button>
                    <Button style={{ backgroundColor: '#3B5998' }}>
                        <Icon name="logo-facebook" />
                    </Button>
                    <Button style={{ backgroundColor: '#DD5144' }}>
                        <Icon name="mail" />
                    </Button>
                </Fab>
            </ScrollView>

    )
}

DetailsScreen.propTypes = {
    route: PropTypes.object,
    imgs: PropTypes.arrayOf(PropTypes.string),
}



const mapStateToProps = state => ({
    event: state.EventData.focusedEvent,
  });

  const ActionCreators = Object.assign(
    {},
      {setOwnedEvents},
  );
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(DetailsScreen)