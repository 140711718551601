import firebase from 'firebase';
import { getPerson, starEvent, unStarEvent } from '../redux/actions/PersonActions'

export function starEventForProfile(eventId) {
    return function (dispatch) {
        return firebase.firestore().collection('userData')
            .doc(firebase.auth()
                .currentUser.uid)
            .set({
                starredEvents: firebase.firestore.FieldValue.arrayUnion(eventId)
            }, { merge: true }).then(
                starResult => dispatch(starEvent(eventId)),
                error => console.log(error)
            ).catch(err => console.log(err));
    }
};

export function unStarEventForProfile(eventId) {
    return function (dispatch) {
        return firebase.firestore().collection('userData')
            .doc(firebase.auth()
                .currentUser.uid)
            .set({
                starredEvents: firebase.firestore.FieldValue.arrayRemove(eventId)
            }, { merge: true }).then(
                unStarResult => dispatch(unStarEvent(eventId)),
                error => console.log(error)
            );
    }
};

export function getUserDataForProfile(authSession) {
    return function (dispatch) {
        try {
            const user = authSession ? authSession.user : firebase.auth().currentUser;
            return firebase.firestore().collection('userData')
                .doc(user.uid).get().then(
                        userData => dispatch(getPerson(userData)),
                        error => console.log(error)
                    )
                .catch(function (error) {
                    console.log('Error getting userData', error);
                });
        } catch (e) {
            console.log(e)
        }
    }
};