import { View, TextInput } from 'native-base';
import React from 'react';
import { connect } from 'react-redux';

function AdminScreen(props){

    const setEventField = e => {
        const { name, value } = e.target;
        setEventObject(eventObject => ({
            ...eventObject,
            [name]: value
        }));
    };

    return(
        <View>
        Create an event:
        Event Name:
        <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.eventName}
                name={'eventName'}
                placeholder={'eventName'}
            />
        link:
        <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.link}
                placeholder={'link'}
            />
        Start date:
            <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.date.start}
                placeholder={'Start date'}
            />
        End Date:
        <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.date.end}
                placeholder={'End date'}
            />
        addressLocality
            <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.location.addressLocality}
                placeholder={'addressLocality'}
            />
        addressRegion
            <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.location.addressRegion}
                placeholder={'addressRegion'}
            />
        postalCode
            <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.location.postalCode}
                placeholder={'postalCode'}
            />
        Street Address
        <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.location.streetAddress}
                placeholder={'Street'}
            />
        description
            <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.description}
                placeholder={'description'}
            />
        admins
            <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.admins}
                placeholder={'admins'}
            />
        images
            <TextInput
                style={styles.input}
                onChangeText={() => setEventField}
                value={eventObject.images}
                placeholder={'images'}
            />
            <Button
                title={'Add Event'}
                onPress={() => createEvent(eventObject)}
            />
            <Button
                title={common.sign_out}
                onPress={() => {
                    props.dispatch(signOutFromFirebase())
                }}
            />
            </View>
    );
}

const mapStateToProps = state => {
    return ({
        personInfo: state.PersonData,
        authData: state.AuthData
    });
};

export default connect(mapStateToProps)(AdminScreen);