import React, {useRef, useState, useEffect} from 'react';
import Carousel, {ParallaxImage} from 'react-native-snap-carousel';
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native';


const {width: screenWidth} = Dimensions.get('window');

const ImageCarousel = props => {
    const ENTRIES1 = props.imgs;
    const [entries, setEntries] = useState([]);
    const carouselRef = useRef(null);

    useEffect(() => {
        setEntries(ENTRIES1);
    }, []);

    const renderItem = ({item, index}, parallaxProps) => {
    return (
        <View style={styles.item}>
        <ParallaxImage
            source={{uri: item.illustration}}
            containerStyle={styles.imageContainer}
            style={styles.image}
            parallaxFactor={0.4}
            {...parallaxProps}
        />
        <Text style={styles.title} numberOfLines={2}>
            {item.title}
        </Text>
        </View>
    );
    };

    return (
    <View style={styles.container}>
        <Carousel
        ref={carouselRef}
        sliderWidth={screenWidth}
        sliderHeight={screenWidth -150}
        itemWidth={screenWidth - 60}
        data={entries}
        renderItem={renderItem}
        hasParallaxImages={true}
        />
    </View>
    );
    };

    export default ImageCarousel;

    const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    item: {
        width: screenWidth - 40,
        height: screenWidth - 40,
    },
    imageContainer: {
    flex: 1,
    marginBottom: Platform.select({ios: 0, android: 1}), // Prevent a random Android rendering issue
    backgroundColor: 'white',
    },
    image: {
    ...StyleSheet.absoluteFillObject,
    resizeMode: 'cover',
    },
    });