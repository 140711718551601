import React from 'react';
import {useTheme} from "@react-navigation/native";
import {Button, Text} from 'native-base';
import {openMap} from "../utils";

export const makeAddress = (address) => {
    let fullAddress = "";
    const COMMA = ',';

    if(address.streetAddress) {
        fullAddress += address.streetAddress;
    }
    if(address.addressLocality) {
        fullAddress += COMMA + " " + address.addressLocality;
    }
    if(address.addressRegion) {
        fullAddress += COMMA + " " + address.addressRegion;
    }
    if(address.postalCode) {
        fullAddress += " " + address.postalCode;
    }
    return fullAddress;
}


const Address = ({address, link}) => {
    const {colors} = useTheme();
    const fullAddress = makeAddress(address);
    return (
        <>
            {
                link ?
                    <Button style={{fontWeight:"500", margin: -10, marginLeft:-17}} onPress={() => openMap(fullAddress)} transparent>
                        <Text>{fullAddress}</Text>
                    </Button>
                    : <Text style={{fontWeight:"500", color: colors.text}}>{fullAddress}</Text>
            }
        </>
    );
}

Address.propTypes = {
}
export default Address;