import React, { useMemo, useState } from 'react';
import { Text } from "native-base";
import { View, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { useTheme } from "@react-navigation/native";
import { getUserDataForProfile } from '../api/PersonDataAPI';
import { saveLoginToState } from '../redux/actions/AuthActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const LoginScreen = (props) => {
    let [userName, setUserName] = useState('');
    let [password, setPassword] = useState('');
    const { colors } = useTheme();
    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                flex: 1,
                backgroundColor: colors.background,
                alignItems: 'center',
                justifyContent: 'center',
            },
            logo: {
                fontWeight: "bold",
                fontSize: 50,
                color: colors.text,
                marginBottom: 40
            },
            inputView: {
                width: "80%",
                backgroundColor: "#FFFFFF",
                borderRadius: 25,
                height: 50,
                marginBottom: 20,
                justifyContent: "center",
                padding: 20
            },
            inputText: {
                height: 50,
                color: "black"
            },
            forgot: {
                color: colors.text,
                fontSize: 11
            },
            loginText: {
                color: colors.text,
                fontSize: 20
            },
            signupBtn: {
                width: "80%",
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: -10
            },
            loginBtn: {
                width: "10%",
                backgroundColor: colors.primary,
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: 40,
                marginBottom: 10
            },
            forgotBtn: {
                width: "80%",
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: -20
            }
        })
    }, [useTheme, colors]);

    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            firebase.auth.OAuthProvider('apple.com')
        ],
        callbacks: {
            signInSuccessWithAuthResult: (success) => {
                props.actions.saveLoginToState(success);
                props.actions.getUserDataForProfile(success);
            },
        },
    };
    return (
        <div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            <View style={styles.container}>
                <TouchableOpacity style={styles.loginBtn} onPress={() => { props.navigation.navigate('SignUp') }}>
                    <Text style={styles.loginText}>Create account with Email</Text>
                </TouchableOpacity>
            </View>
        </div>
    );
};

LoginScreen.propTypes = {

};

const ActionCreators = Object.assign(
    {},
    { saveLoginToState },
    { getUserDataForProfile }
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});


const mapStateToProps = state => ({
    auth: state.AuthData.userToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)