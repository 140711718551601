import { ListItem, Text} from "react-native-elements";
import { Icon, Button } from 'native-base';
import React, { useMemo } from "react";
import { useTheme } from "@react-navigation/native";
import { StyleSheet } from "react-native";
import common from '../text/Common.json';
import { makeCalEvent } from "../utils";
import moment, {tz, weekdaysShort} from 'moment';

const CalendarCard = ({date})=>{
    const {colors} = useTheme();
    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                width: "100%"
            },
            backgroundColor: {
                backgroundColor: colors.background
            },
            textColor: {
                color: colors.text
            },
            textHeader: {
                fontWeight: "bold"
            }
        });
    })
    const day = useMemo(() => {
        const momentDate = moment(date.start);
        const weekDay = weekdaysShort(momentDate.day());
        const calendarDate = momentDate.format("MMM DD, YYYY");
        return (
            `${weekDay}, ${calendarDate}`
        );
    }, [date])
    const timeRange = useMemo(() => {
        const momentStartDate = moment(date.start);
        const momentEndDate = moment(date.end);
        return (
            `${momentStartDate.format("h:mmA")} - ${momentEndDate.format("h:mmA")}`
        );
    }, [date])
    return (
        <ListItem containerStyle={styles.backgroundColor} style={[styles.container, styles.backgroundColor]}>
            <Icon style={{color:colors.text, marginTop: -25}} name='calendar'/>
            <ListItem.Content style={[styles.textColor, styles.backgroundColor]}>
                {
                    day ?
                        <ListItem.Title style={[styles.textColor, styles.backgroundColor]}>
                            {day}
                        </ListItem.Title> :
                        <></>
                }
                {
                    timeRange ?
                        <ListItem.Subtitle style={[styles.textColor, styles.backgroundColor]}>
                            {timeRange}
                        </ListItem.Subtitle>
                        :
                        <></>
                }
                <Button transparent onPress={() => makeCalEvent()} t>
                    <Text style={{color: colors.primary}}>{common.add_to_cal}</Text>
                </Button>
            </ListItem.Content>
        </ListItem>
    )
}

export default CalendarCard;