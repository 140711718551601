import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { signInWithExistingEmail } from '../api/AuthDataApi';
import { View, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { saveLoginToState } from '../redux/actions/AuthActions';
import { bindActionCreators } from 'redux';
import { useTheme } from "@react-navigation/native";
import { Text } from "native-base";

function SignUpScreen(props) {
    let [userName, setUserName] = useState('');
    let [password, setPassword] = useState('');
    const { colors } = useTheme();
    const styles = useMemo(() => {
        return StyleSheet.create({
            container: {
                flex: 1,
                backgroundColor: colors.background,
                alignItems: 'center',
                justifyContent: 'center',
            },
            logo: {
                fontWeight: "bold",
                fontSize: 50,
                color: colors.text,
                marginBottom: 40
            },
            inputView: {
                width: "80%",
                backgroundColor: "#FFFFFF",
                borderRadius: 25,
                height: 50,
                marginBottom: 20,
                justifyContent: "center",
                padding: 20
            },
            inputText: {
                height: 50,
                color: "black"
            },
            forgot: {
                color: colors.text,
                fontSize: 11
            },
            loginText: {
                color: colors.text,
                fontSize: 20
            },
            signupBtn: {
                width: "80%",
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: -10
            },
            loginBtn: {
                width: "80%",
                backgroundColor: colors.primary,
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: 40,
                marginBottom: 10
            },
            forgotBtn: {
                width: "80%",
                borderRadius: 25,
                height: 50,
                alignItems: "center",
                justifyContent: "center",
                marginTop: -20
            }
        })
    }, [useTheme, colors]);
    return (
        <View>
            Username:
            <TextInput
                style={styles.input}
                onChangeText={setUserName}
                value={userName}
                placeholder={'userName'}
            />
            Password:
            <TextInput
                style={styles.input}
                onChangeText={setPassword}
                value={password}
                placeholder={'Password'}
            />
            <TouchableOpacity style={styles.loginBtn} onPress={() => {
                props.actions.saveLoginToState(signInWithExistingEmail(userName, password));
            }}>
                <Text style={styles.loginText}>Create account with email</Text>
            </TouchableOpacity>
        </View>
    );
}

function mapStateToProps(state) {
    return {};
}

const ActionCreators = Object.assign(
    {},
    { saveLoginToState },
);
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ActionCreators, dispatch),
});


export default connect(
    mapStateToProps, mapDispatchToProps
)(SignUpScreen);