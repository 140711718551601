import firebase from 'firebase';
import { saveLoginToState, signOut } from '../redux/actions/AuthActions'


function signInWithGoogle() {
  // Sign into Firebase using popup auth & Google as the identity provider.
  return function (dispatch) {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider).then(
      auth => { dispatch(saveLoginToState(auth)) },
      error => console.log(error)
    );
  }
}

function signInWithFacebook() {
  // Sign into Firebase using popup auth & Facebook as the identity provider.
  return function (dispatch) {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider).then(
      auth => { dispatch(saveLoginToState(auth)) },
      error => console.log(error)
    );
  }
}

function signInWithApple() {
  // Sign into Firebase using popup auth & Apple as the identity provider.
  return function (dispatch) {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return firebase.auth().signInWithPopup(provider).then(
      auth => { dispatch(saveLoginToState(auth)) },
      error => console.log(error)
    );
  }
}

function signUpWithEmail(email, password) {
  return function (dispatch) {
    return firebase.auth().createUserWithEmailAndPassword(email, password).then(
      auth => { dispatch(saveLoginToState(auth)) },
      error => console.log(error)
    );
  }
}

function signInWithExistingEmail(email, password) {
  return function (dispatch) {
    return firebase.auth().signInWithEmailAndPassword(email, password).then(
      auth => { dispatch(saveLoginToState(auth)) },
      error => console.log(error)
    );
  }
}

function signOutFromFirebase() {
  console.log();
  return function (dispatch) {
    return  firebase.auth().signOut().then(
      auth =>  dispatch(signOut(auth)),
      error => console.log(error)
    );
  }
}


export {
  signInWithGoogle, signInWithApple, signInWithFacebook,
  signUpWithEmail, signInWithExistingEmail, signOutFromFirebase
};